import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, forkJoin } from "rxjs";
import { ConfigService } from "./config.service";
import { switchMap, tap } from "rxjs/operators";
import { LG_USER_INFO } from "@logex/framework/lg-application";
import { ApiType } from "@shared/types/api-type";
import { AppUser } from "@shared/types/app-user";
import { ApplicationPermissions } from "@shared/types/app-permissions";
import { UserProfile } from "@shared/types/authorization.types";

export interface ProductPermission extends PermissionOrganizationDetail {
    product: string;
    applicationInstance: string;
    permissions: string[];
}

export interface PermissionOrganizationDetail {
    agbCode: string;
    cicCode: number;
    mrdmUri: string;
    organizationId: number;
    name: string;
    position: string;
}

export interface SearchPermissionArgs {
    applicationInstances: string[];
    organizations: string[];
    products: string[];
    addonsAsParentProduct?: boolean;
}

export interface ResourceSearchArgs {
    applicationInstances: string[];
}

export interface OrganizationResource {
    organizationId: number;
    resources: Resource[];
}

export interface Resource {
    resourceType: string;
    value: string;
    canEdit: boolean;
}

@Injectable({ providedIn: "root" })
export class PermissionService {
    private _configService = inject(ConfigService);
    private _httpClient = inject(HttpClient);
    private _userInfo = inject<AppUser>(LG_USER_INFO);
    public userProfile: UserProfile;
    public permissions: ProductPermission[];
    public resources: OrganizationResource[] = [];

    // Global permissions
    public isInternal: boolean;

    private _standardPermissions: string[] = [ApplicationPermissions.SWITCH_LANGUAGE];

    hasAccess$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    getPermissions(): Observable<[ProductPermission[], UserProfile]> {
        const args: SearchPermissionArgs = {
            applicationInstances: [this._configService.configuration.instance],
            products: [],
            organizations: [],
            addonsAsParentProduct: true
        };

        return forkJoin([
            this._httpClient.post<ProductPermission[]>(`user/permissions/search`, args, {
                headers: { type: ApiType.Authorization }
            }),
            this._httpClient.get<UserProfile>(`user/profile`, {
                headers: { type: ApiType.Authorization }
            })
        ]).pipe(
            tap(([permissions, userProfile]) => {
                this._setPermissions(permissions);
                this._setUserProfile(userProfile);
            })
        );
    }

    isInternalForWorkflow(organizationId: number): boolean {
        const { organizationPermission } = this._findOrganizationPermissions(organizationId);

        return organizationPermission?.permissions.includes(ApplicationPermissions.INTERNAL);
    }

    private _findOrganizationPermissions(organizationId: number): {
        organizationPermission: ProductPermission;
        organizationResource: OrganizationResource;
    } {
        const organizationPermission = this.permissions.find(
            p => p.organizationId === organizationId
        );
        const organizationResource = this.resources.find(r => r.organizationId === organizationId);

        return { organizationPermission, organizationResource };
    }

    private _setPermissions(permissions: ProductPermission[]): void {
        this.permissions = permissions;

        const allPermissions = permissions.reduce(
            (acc: string[], x: ProductPermission) => acc.concat(x.permissions),
            []
        );
        const uniquePermissions = [...new Set(allPermissions)];
        uniquePermissions.forEach(up => {
            if (this._standardPermissions.includes(up)) uniquePermissions.push(up.substring(4));
        });

        this._userInfo.roles = {};

        uniquePermissions.forEach(x => {
            this._userInfo.roles[x] = true;
        });

        this.hasAccess$.next(uniquePermissions.includes(ApplicationPermissions.READ));

        this.isInternal = this._userInfo.hasPermission(ApplicationPermissions.INTERNAL);
    }

    private _setUserProfile(userProfile: UserProfile): void {
        this._userInfo.id = userProfile.accountId;
        this._userInfo.email = userProfile.email;
        this._userInfo.name = `${userProfile.firstName} ${userProfile.lastName}`;
        this._userInfo.impersonator = userProfile.impersonation?.originalUserEmail;
        this._userInfo.impersonatorName = userProfile.impersonation?.originalUserName;
        this._userInfo.internal = userProfile.isInternal;
        this._userInfo.login = userProfile.email;
    }

    private _setResources(resources: OrganizationResource[]): void {
        this.resources = resources;
    }
}
