import { AppAccessGuard } from "@shared/services/app-access.guard";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
    {
        path: "callback",
        redirectTo: "",
        pathMatch: "full"
    },
    {
        path: "unauthorized",
        loadChildren: () =>
            import("./unauthorized/unauthorized.module").then(m => m.UnauthorizedModule)
    },
    {
        path: "",
        loadChildren: () => import("./authorized/authorized.module").then(m => m.AuthorizedModule),
        canMatch: [AppAccessGuard]
    },
    {
        path: "**",
        redirectTo: "",
        pathMatch: "full"
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
