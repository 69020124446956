import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import localeEn150 from "@angular/common/locales/en-150";
import localeEnGb from "@angular/common/locales/en-GB";

import { IAppInfo, LG_APP_CONFIGURATION, LG_APP_INFO } from "@logex/framework/lg-application";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { AppConfiguration } from "@shared/services";
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";

@Component({
    selector: "app-root",
    template: `<router-outlet></router-outlet>`,
    changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent {
    constructor(
        @Inject(LG_APP_CONFIGURATION) public appConfiguration: AppConfiguration,
        @Inject(LG_APP_INFO) public appInfo: IAppInfo,
        private _router: Router,
        private _loaderService: LgLoaderService
    ) {
        registerLocaleData(localeNl);
        registerLocaleData(localeEnGb);
        registerLocaleData(localeEn150);

        this._router.events.subscribe(ev => {
            if (ev instanceof RouteConfigLoadStart) {
                this._loaderService.show();
            } else if (ev instanceof RouteConfigLoadEnd) {
                this._loaderService.hide();
            }
        });
    }
}
