import { IAppInfo, IAppSession } from "@logex/framework/lg-application";
import { Auth0Config } from "@shared/auth0";

/** Static configuration obtained by reading appsettings.json  */

export interface IAppServices {
    authentication: {
        authority: string;
        clientId: string;
        audience: string;
        type?: "auth0" | "generic";
    };

    configuration: string;
    userSettings: string;
    authorization: string;
    application: string;
}

export interface AppStaticConfiguration {
    clientId: string;
    product: string;
    instance: string;
    environment: "development" | "execution" | "production" | string;
    availableLanguages: string;
    defaultLanguage: string;
    fallbackLanguage: string;
    currency: string;
    backendUrl: string;
    services: IAppServices;
}

// Our framework doesn't currently use strict typing. We strongly recommend using
// strict typing for tools, but sometimes it means forcing the system (the exclamation marks)

/** LgBackend-related configuration served to framework */
export class AppConfiguration {
    environment: string;
    instance: string;
    applicationRoot: string;
    authorization: string;
    notificationUrl: string;
    auth0: Auth0Config;
    dataConnectUrl: string;
    refDataUrl: string;
    dataDiveUrl: string;
    matomoSiteId?: number;
    isSecondVersion: boolean;
    localization: LocalizationConfiguration;
}

export class LocalizationConfiguration {
    availableLanguages: string;
    defaultLanguage: string;
    fallbackLanguage: string;
}

/** Generic configuration served to the framework  */
export class AppInfo implements IAppInfo {
    environment!: string;
    fullAppName!: string;
    productId!: string;
    toolInstanceName!: string;
    versionNumber!: string;
    overrideCurrencyPrefix!: string;
    overrideCurrencySuffix!: string;

    doNotDoGaTracking(): boolean {
        return true; // in reality might be connected to f.ex "localhost" domain
    }

    isProduction(): boolean {
        return this.environment?.toLowerCase() === "production";
    }
}

/** This is LgBackend-related session. There are 2 commonly uses services that require it, so for now we just mock it */
export class AppSession implements IAppSession {
    clientId = 1;

    client = {
        id: 1,
        code: "1",
        name: "Dummy"
    };

    scenarioId = 0;
}
