import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ApplicationPermissions {
    static readonly INTERNAL = "scm_internal";
    static readonly SWITCH_LANGUAGE = "scm_switch_language";
    static readonly ADMIN = "scm_admin";
    static readonly EDIT = "scm_edit";
    static readonly READ = "scm_read";
}
