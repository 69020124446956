import { Injectable, inject } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { mergeMap, catchError, switchMap, first, tap } from "rxjs/operators";
import { Auth0AuthorizationService } from "./auth0-authorization.service";
import { urlConcat } from "@logex/framework/utilities";
import { ConfigService } from "../services/config.service";
import { ApiType } from "@shared/types/api-type";
import { LgPromptDialog } from "@logex/framework/ui-core";

@Injectable()
export class Auth0InterceptorService implements HttpInterceptor {
    private _auth = inject(Auth0AuthorizationService);
    private _config = inject(ConfigService);
    private _promptDialog = inject(LgPromptDialog);
    private static _sessionExpiredDialogVisible = false;
    private static _errorDialogVisible = false;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._auth.configured || req.url.includes("localization")) return next.handle(req);

        let url = this._config.configuration.applicationRoot;
        if (req.headers.get("type") && req.headers.get("type") === ApiType.Authorization) {
            url = this._config.configuration.authorization;
        }
        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(loggedIn => (loggedIn ? this._auth.getTokenSilently() : of(null))),
            catchError(e => {
                if (!Auth0InterceptorService._sessionExpiredDialogVisible) {
                    Auth0InterceptorService._sessionExpiredDialogVisible = true;

                    this._handleSessionExpiration(e);
                }
                return of(null);
            }),
            mergeMap(token => {
                const tokenReq = token
                    ? req.clone({
                          url: url ? urlConcat(url, req.url) : req.url,
                          headers: new HttpHeaders({
                              Authorization: `Bearer ${token}`
                          })
                      })
                    : req;
                return next
                    .handle(tokenReq)
                    .pipe
                    // tap({
                    //     error: e => {
                    //         if (e.status === 400 && e.error) {
                    //             this._showBadRequestDialog(e);
                    //         }
                    //         if (e?.status === 401) {
                    //             this._handleSessionExpiration(e);
                    //         } else {
                    //             this._showErrorDialog();
                    //         }
                    //     }
                    // })
                    ();
            }),
            catchError(err => throwError(err))
        );
    }

    private _showBadRequestDialog(e: any): void {
        if (!Auth0InterceptorService._errorDialogVisible) {
            Auth0InterceptorService._errorDialogVisible = true;
            this._promptDialog
                .alertLc("FW.FAILED_REQUEST_TO_SERVER", e.error || "APP._Errors.SomethingWentWrong")
                .then(() => (Auth0InterceptorService._errorDialogVisible = false));
        }
    }

    private _handleSessionExpiration(e: any): void {
        if (!Auth0InterceptorService._sessionExpiredDialogVisible) {
            Auth0InterceptorService._sessionExpiredDialogVisible = true;

            this._promptDialog
                .alertLc("FW.FAILED_REQUEST_TO_SERVER", "FW.SESSION_EXPIRED", { allowClose: false })
                .then(() => {
                    window.location.reload();
                });
        }
        e.force_quiet = true;
    }

    private _showErrorDialog(): void {
        if (!Auth0InterceptorService._errorDialogVisible) {
            Auth0InterceptorService._errorDialogVisible = true;
            this._promptDialog
                .alertLc("FW.FAILED_REQUEST_TO_SERVER", "APP._Errors.SomethingWentWrong")
                .then(() => (Auth0InterceptorService._errorDialogVisible = false));
        }
    }
}
