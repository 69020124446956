import { NgModule } from "@angular/core";
import { Auth0SidebarUserComponent } from "./components/auth0-sidebar/auth0-sidebar-user.component";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { PivotSearchComponent } from "./components/pivot-search/pivot-search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@logex/framework/ui-core";

@NgModule({
    declarations: [Auth0SidebarUserComponent, PivotSearchComponent],
    imports: [LgLocalizationModule, FormsModule, ReactiveFormsModule, CommonModule, UiCoreModule],
    exports: [Auth0SidebarUserComponent, PivotSearchComponent]
})
export class SharedModule {}
