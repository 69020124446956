import { Injectable, inject } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { LocalizationConfiguration } from "../types/app-configuration";

const LANGUAGE_STORAGE_KEY = "preferedLanguage";

@Injectable()
export class AppLocalizationSettings implements LgLocalizationSettings {
    private _lgLoaderService = inject(LgLoaderService);
    private _lcConfig: LocalizationConfiguration;
    readonly bootstrapDone: Promise<void>;

    private setReady: () => void;

    readonly languages: Record<any, any> = {};

    private _preferredLanguage;

    constructor() {
        this.bootstrapDone = new Promise(resolve => {
            this.setReady = resolve;
        });
    }

    get fallbackLanguage(): string {
        return "en";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return this._lcConfig.availableLanguages.split(",").map(l => l.trim());
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get userPreferedLanguage(): string {
        return localStorage.getItem(LANGUAGE_STORAGE_KEY);
    }

    get currency(): string {
        return "EUR";
    }

    addStrings(lang: string, strings: Record<any, any>): void {
        this.languages[lang] = [strings];
    }

    setPreferredLanguage(lang: string): void {
        localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
    }

    init(config: LocalizationConfiguration): void {
        this._lcConfig = config;
        this._initUserLanguage();
        this.setReady();
    }

    deleteUserPreferedLanguage(): void {
        localStorage.removeItem(LANGUAGE_STORAGE_KEY);
    }

    switchCurrentLanguage(lang: string): void {
        this._lgLoaderService.show();
        this.setPreferredLanguage(lang);
        window.location.reload();
    }

    private _initUserLanguage(): void {
        if (this.userPreferedLanguage) {
            if (this.availableLanguages.includes(this.userPreferedLanguage)) {
                this._preferredLanguage = this.userPreferedLanguage;
            } else {
                this.deleteUserPreferedLanguage();
            }
        } else {
            this._preferredLanguage = this._lcConfig.defaultLanguage;
        }
    }
}
