import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";

import { LG_ICON_LOADER_CONTENT, UiCoreModule } from "@logex/framework/ui-core";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import { LgExportsModule } from "@logex/framework/lg-exports";
import { CoreModule, LgConsoleConfiguration } from "@logex/framework/core";

import icons from "./shared/icons/app-icons-24x24.def";

import {
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_AUTHORIZATION_SERVICE,
    LG_NAVIGATION,
    LG_USER_INFO,
    LgAuthorizationServiceV2,
    AppUser,
    LG_APP_CONTROL,
    LgAppControlV1Service
} from "@logex/framework/lg-application";
import {
    LgLayoutModule,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE
} from "@logex/framework/lg-layout";
import {
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    ReferenceTranslateCompiler,
    useMessageFormatLocales
} from "@logex/framework/lg-localization";

import { SharedModule } from "@shared/shared.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
    AppConfiguration,
    AppInfo,
    AppLocalizationSettings,
    AppSession,
    AppStartupService,
    ConfigService
} from "@shared/services";
import { getNavigation } from "@shared/app-navigation";
import { ApplicationPermissions } from "@shared/types/app-permissions";
import { AUTH0_CONFIG, Auth0InterceptorService } from "@shared/auth0";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        CoreModule,
        UiCoreModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        LgFiltersetModule.forRoot(),
        LgExportsModule,
        LgLayoutModule,
        SharedModule,
        AppRoutingModule
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                return config;
            }
        },
        // must contain any selectable locale
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings
        },
        {
            provide: ConfigService,
            useClass: ConfigService
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new AppConfiguration()
        },
        {
            provide: LG_APP_INFO,
            useFactory: () => new AppInfo()
        },
        {
            provide: LG_APP_SESSION,
            useFactory: () => new AppSession()
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (appPermissions: ApplicationPermissions) => getNavigation(appPermissions),
            deps: [ApplicationPermissions]
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: LG_AUTHORIZATION_SERVICE,
            useClass: LgAuthorizationServiceV2
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (startup: ConfigService) =>
                startup.configurationPromise.then(config => config.auth0),
            deps: [ConfigService]
        },
        {
            provide: LG_APP_CONTROL,
            useClass: LgAppControlV1Service
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: icons,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
