import { Injectable, inject } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { Auth0AuthorizationService } from "../auth0/auth0-authorization.service";
import { LG_USER_INFO } from "@logex/framework/lg-application";
import { PermissionService } from "./app-permission.service";
import { AppUser } from "@shared/types/app-user";
import { Router, Route, UrlSegment } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AppAccessGuard {
    private _auth = inject(Auth0AuthorizationService);
    private _permissions = inject(PermissionService);
    private _router = inject(Router);
    private _userInfo = inject<AppUser>(LG_USER_INFO);
    _hasAccess = false;

    canMatch(
        _route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this._hasAccess) return true;
        return this._checkAccess(segments);
    }

    private _checkAccess(segments: UrlSegment[]): Observable<boolean> {
        return this._auth.isAuthenticated$.pipe(
            switchMap(loggedIn => {
                if (!loggedIn) {
                    const fullPath = segments.reduce((path, currentSegment) => {
                        return `${path}/${currentSegment.path}`;
                    }, "");
                    this._auth.login(fullPath);
                    return EMPTY;
                } else {
                    return this._permissions.hasAccess$.pipe(
                        tap(hacAccess => {
                            if (!hacAccess) {
                                this._router.navigate(["unauthorized"]);
                            }
                        })
                    );
                }
            })
        );
    }
}
