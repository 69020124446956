import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, first, firstValueFrom, Observable, of, switchMap, tap } from "rxjs";
import { ConfigService } from "./config.service";
import { Auth0AuthorizationService } from "../auth0";
import { LG_APP_CONFIGURATION } from "@logex/framework/lg-application";
import { AppLocalizationSettings } from "./app-localization.service";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";
import { PermissionService, ProductPermission } from "./app-permission.service";
import { AppConfiguration } from "../types/app-configuration";
import { UserProfile } from "@shared/types/authorization.types";

@Injectable({
    providedIn: "root"
})
export class AppStartupService {
    private _auth = inject(Auth0AuthorizationService);
    private _configService = inject(ConfigService);
    private _http = inject(HttpClient);
    private _lgAppConfig = inject(LG_APP_CONFIGURATION);
    private _localizationSettings = inject<AppLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    private _permissionService = inject(PermissionService);

    initialize(): () => Promise<void> {
        return async () => {
            try {
                await this._loadConfig();
            } catch (e) {
                console.log(e);
            }
        };
    }

    private async _loadConfig(): Promise<void> {
        await firstValueFrom(
            this._http.get<AppConfiguration>("appsettings.json").pipe(
                tap(c => {
                    this._configService._setConfigation(c);
                    this._localizationSettings.init(c.localization);
                    this._lgAppConfig.testMachine = `${c.instance} - ${c.environment}`;
                }),
                switchMap(() => this._loadPermissions())
            )
        );
    }

    private _loadPermissions(): Observable<[ProductPermission[], UserProfile]> {
        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(isAuth =>
                isAuth
                    ? this._permissionService.getPermissions()
                    : of({} as [ProductPermission[], UserProfile])
            ),
            catchError(() => of({} as [ProductPermission[], UserProfile]))
        );
    }
}
