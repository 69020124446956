import { INavNode } from "@logex/framework/lg-application";
import { NavigationIds } from "@shared/navigation-ids";
import { ApplicationPermissions } from "./types/app-permissions";

const navTree: INavNode[] = [
    {
        path: "",
        noBreadcrumb: true,
        narrowed: false,
        id: "root",

        children: [
            {
                path: "editor",
                id: "editor",
                permissions: [ApplicationPermissions.ADMIN],
                narrowed: false,
                noBreadcrumb: true,
                hidden: true
            },
            {
                path: "editor/start",
                lid: "APP._Menu.Start",
                id: NavigationIds.EditorStart,
                permissions: [ApplicationPermissions.ADMIN],
                narrowed: false,
                noBreadcrumb: false
            },
            {
                path: "editor/dataSets",
                lid: "APP._Menu.DataSets",
                id: NavigationIds.DataSets,
                permissions: [ApplicationPermissions.ADMIN],
                narrowed: false
            },
            {
                path: "editor/entityListGroups",
                lid: "APP._Menu.EntityListGroups",
                id: NavigationIds.EntityListGroups,
                permissions: [ApplicationPermissions.ADMIN],
                narrowed: false
            },
            {
                path: "editor/entityLists",
                lid: "APP._Menu.EntityLists",
                id: NavigationIds.EntityLists,
                permissions: [ApplicationPermissions.ADMIN],
                narrowed: false
            }
            // {
            //     path: "editor/dataSets/create",
            //     lid: "APP._Menu.NewDataSet",
            //     id: NavigationIds.DataSetDraftCreate,
            //     permissions: [ApplicationPermissions.ADMIN],
            //     narrowed: false
            // },
            // {
            //     path: "editor/entityLists/create",
            //     lid: "APP._Menu.NewEntityList",
            //     id: NavigationIds.EntityListDraftCreate,
            //     permissions: [ApplicationPermissions.ADMIN],
            //     narrowed: false
            // },
            // {
            //     path: "editor/entityListGroups/create",
            //     lid: "APP._Menu.NewEntityListGroup",
            //     id: NavigationIds.EntityListGroupDraftCreate,
            //     permissions: [ApplicationPermissions.ADMIN],
            //     narrowed: false
            // }
        ]
    }, // root

    // --------------------------------------------------------------------------------------------
    {
        path: "settings",
        noBreadcrumb: false,
        lid: "APP._Menu.Settings",
        hidden: true,
        id: "settings", // this can be used to set root both for the settings menu, and breadcrumb in settings pages
        noNumber: true,
        narrowed: false,
        permissions: [ApplicationPermissions.ADMIN],

        children: [
            {
                path: "general",
                id: "settings-general",
                lid: "APP._Menu.Settings_general",
                narrowed: false
                // children: [
                //     {
                //         path: "editor",
                //         lid: "APP._Menu.Settings_demo",
                //         id: "settings-demo",
                //         hidden: false,
                //         permissions: [ApplicationPermissions.Read],
                //         narrowed: false
                //     }
                // ]
            }
        ]
    }, // Settings
    // --------------------------------------------------------------------------------------------
    {
        path: "login",
        anonymous: true,
        narrowed: true,
        hidden: true
    }
];

export const getNavigation: (permissions: ApplicationPermissions) => INavNode[] = (
    _permissions
): INavNode[] => {
    return navTree;
};
